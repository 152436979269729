@charset "utf-8";
@use "sass:color";
@font-face {
  font-family: "National Park Typeface";
  src: url("../assets/font/NationalPark/NationalPark-VariableVF.ttf") format("truetype");
  src: url("../assets/font/NationalPark/NationalPark-VariableVF.woff") format("woff");
  src: url("../assets/font/NationalPark/NationalPark-VariableVF.woff2") format("woff2");
}

// Define defaults for each variable.

$base-font-family: "National Park Typeface" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #ff00ff !default;

$grey-color:       #828282 !default;
$grey-color-light: color.adjust($grey-color, $lightness: 40%) !default;
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/images",
  "minima/layout",
  "minima/syntax-highlighting"
;
