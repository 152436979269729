.floating-image {
    margin: 15px;
    display: inline-block;
}

.floating-image-img {
    display: block;
    height: auto;
    border-radius: 5px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.floating-image-caption {
    text-align: center;
    font-style: italic;
    word-wrap: break-word;
    overflow-wrap: break-word;
}